import { uniqueId } from 'lodash-es';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { DefaultTooltip } from 'components/common/tooltip/Tooltip';
import { useServiceTrackingContext } from 'components/common/tracking/serviceTrackingContext';
import { useHasAccessServices } from 'components/hooks/services/useBoardServices';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { createUserBookmark, deleteUserBookmark } from 'core/services/bookmark/userBookmarkService';
import { FeatureTracked } from 'models/tracking';
import { useBookmark } from 'components/hooks/bookmark/useBookmark';
import { BookmarkOrigin } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

import './BookmarkButtons.scss';

type BookmarkButtonProps = {
    referenceId: string;
    origin: Nullable<BookmarkOrigin>;
};

enum IconPosition {
    Left = 'Left',
    Right = 'Right'
}

export const BookmarkDropdownItem: FC<BookmarkButtonProps> = ({
    referenceId,
    origin }) => {
    return <BookmarkButtonComp
        referenceId={referenceId}
        origin={origin}
        showButtonLabel={true}
        buttonClass="btn btn-block btn-flat-secondary text-primary btn-lg d-flex justify-content-between ps-3"
        iconClass="icon text-secondary align-self-center "
    />;
};

export const BookmarkButton: FC<BookmarkButtonProps> = ({
    referenceId,
    origin }) => {
    return <BookmarkButtonComp
        referenceId={referenceId}
        origin={origin}
        showButtonLabel={true}
        buttonClass="btn btn-lg btn-icon-text btn-bookmark-inherit"
        bookmarkedButtonClass="btn-secondary"
        unBookmarkedButtonClass="btn-outline-secondary"
        iconPosition={IconPosition.Left}
    />;
};

export const BookmarkStarButton: FC<BookmarkButtonProps> = ({
    referenceId,
    origin,
}) => {
    return <BookmarkButtonComp
        referenceId={referenceId}
        origin={origin}
        showButtonLabel={false}
        buttonClass="btn btn-lg btn-flat-secondary"
    />;
};

type BookmarkButtonCompProps = BookmarkButtonProps & {
    showButtonLabel: boolean;
    buttonClass: string,
    bookmarkedButtonClass?: string;
    unBookmarkedButtonClass?: string;
    iconClass?: string,
    iconPosition?: IconPosition
};

const BookmarkButtonComp: FC<BookmarkButtonCompProps> = ({
    referenceId,
    origin,
    showButtonLabel,
    buttonClass,
    bookmarkedButtonClass,
    unBookmarkedButtonClass,
    iconClass,
    iconPosition,
}) => {
    const buttonId = useMemo(() => uniqueId('bookmark'), []);

    const { trackService } = useServiceTracking();
    const { serviceTracked } = useServiceTrackingContext();

    const dispatch = useDispatch();
    const { t: translate } = useTranslation('bookmark');

    const { hasAccessTo, hasAccessToServicePage } = useHasAccessServices();
    const { getBookmarkByReferenceId } = useBookmark();

    const isOriginSgmService = origin === BookmarkOrigin.SgmService;
    const hasAccessToSgmService = hasAccessTo(referenceId);
    const hasAccessToSgmServicePage = hasAccessToServicePage(referenceId);

    // Should bookmark only access sgm services
    if (isOriginSgmService && (!hasAccessToSgmService && !hasAccessToSgmServicePage)) {
        return <></>;
    }
    const bookmark = getBookmarkByReferenceId(referenceId);
    const bookmarkId = bookmark?.Id;
    const submitBookmark = async (event: React.MouseEvent<any, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        if (bookmarkId && bookmarkId > 0) {
            dispatch<any>(deleteUserBookmark(bookmarkId));
            await trackService(FeatureTracked.RemoveBookmark, serviceTracked);
        } else {
            dispatch<any>(createUserBookmark(referenceId, origin));
            await trackService(FeatureTracked.AddBookmark, serviceTracked);
        }
    };

    const isBookmarked = bookmarkId && bookmarkId > 0;
    const className = classNames(buttonClass, isBookmarked ? bookmarkedButtonClass : unBookmarkedButtonClass, { 'btn-bookmark-no-label': !showButtonLabel });

    const label = isBookmarked
        ? translate('bookmark:bookmarked')
        : translate('bookmark:bookmark');
    const labelTooltip = isBookmarked
        ? translate('bookmark:removeBookmark')
        : translate('bookmark:addToBookmark');

    return (
        <DefaultTooltip text={labelTooltip} enabled={!showButtonLabel && !!labelTooltip} >
            <button
                id={buttonId}
                className={className}
                onClick={async (ev) => await submitBookmark(ev)}
                aria-label="bookmark"
            >
                {(iconPosition === IconPosition.Left) && <BookmarkStartIcon iconClass={iconClass} bookmarkId={bookmarkId} />}
                <span className={classNames({ 'visually-hidden': !showButtonLabel })} title={labelTooltip}>
                    {label}
                </span>
                {(!iconPosition || iconPosition === IconPosition.Right) && <BookmarkStartIcon iconClass={iconClass} bookmarkId={bookmarkId} />}
            </button>
        </DefaultTooltip>
    );
};


const BookmarkStartIcon: FC<{ iconClass?: string, bookmarkId: Nullable<number> }> = ({ iconClass, bookmarkId }) => {
    const isBookmarked = bookmarkId && bookmarkId > 0;
    return <i className={iconClass || 'icon'}>{isBookmarked ? 'star' : 'star_outline'}</i>;
};
