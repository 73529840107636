import { Dispatch } from 'react';
import { ThunkResult } from '../../../store/store';
import {
    createLearnMoreItemFetchFailureAction,
    createLearnMoreItemFetchRequestAction,
    createLearnMoreItemFetchSuccessAction,
} from '../../../store/actions/learnMoreItemAction';
import { LearnMoreApi } from 'services/ApiService/ServiceBoard/ServiceBoardApi';

export const getAvailableLearnMore = (): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        _state,
        { logger },
    ) => {
        dispatch(createLearnMoreItemFetchRequestAction());
        try {
            const learnMore = await LearnMoreApi.getAvailableLearnMore();
            dispatch(createLearnMoreItemFetchSuccessAction(learnMore));
        } catch (error: unknown) {
            logger.error(error);
            dispatch(createLearnMoreItemFetchFailureAction());
        }
    };
};

export const markAsReadLearnMore = (learnMoreId: number): ThunkResult => {
    return async (
        _dispatch: Dispatch<any>,
        _state,
        { logger },
    ) => {
        try {
            await LearnMoreApi.markAsReadLearnMore(learnMoreId);
        } catch (error: unknown) {
            logger.error(error);
        }
    };
};
