import React, { FC } from 'react';
import { ScrollerCarousel } from 'components/common/scroll/ScrollerCarousel';
import { ServiceCard } from 'components/common/service-card/ServiceCard';
import { useCatalogServices } from 'components/hooks/services/useBoardServices';
import { MyHighlightRecommendedDto } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

type HighlightRecommendedCardProps = {
    highlightRecommended: Nullable<MyHighlightRecommendedDto>
}

export const HighlightRecommendedCard: FC<HighlightRecommendedCardProps> = ({ highlightRecommended }) => {
    const { getSgmServiceByKey } = useCatalogServices();

    const services = highlightRecommended?.serviceKeys?.map(x => getSgmServiceByKey(x)).filter(x => x).map(x => x as NonNullable<typeof x>) || [];

    if (!services.length) {
        return <></>;
    }

    return <>
        <ScrollerCarousel name="recommendations" maxDisplayedItems={4}>
            {services.map(service => (
                <div className="col-6 col-md-12" key={service?.ServiceKey}>
                    <ServiceCard service={service} />
                </div>
            ))}
        </ScrollerCarousel>
    </>;
};